"use client";

import { CartProvider, MedusaProvider } from "medusa-react";
import { ReactNode } from "react";

import { MEDUSA_BACKEND_URL, queryClient } from "@/lib/config";
import { AccountProvider } from "@/lib/contexts/account-context";
import { FlyoutProvider } from "@/lib/contexts/flyout-context";
import { LayoutProvider } from "@/lib/contexts/layout-context";
import { LoadingProvider } from "@/lib/contexts/loading-context";
import { SearchProvider } from "@/lib/contexts/search-context";
import { SideModalProvider } from "@/lib/contexts/sidemodal-context";
import { StoreProvider } from "@/lib/contexts/store-context";
import { WishlistProvider } from "@/lib/contexts/wishlist-context";

export default function Providers({ children }: { children: ReactNode }) {
  return (
    <MedusaProvider
      baseUrl={MEDUSA_BACKEND_URL}
      queryClientProviderProps={{
        client: queryClient,
      }}
    >
      <CartProvider>
        <LoadingProvider>
          <StoreProvider>
            <AccountProvider>
              <LayoutProvider>
                <WishlistProvider>
                  <SideModalProvider>
                    <FlyoutProvider>
                      <SearchProvider>{children}</SearchProvider>
                    </FlyoutProvider>
                  </SideModalProvider>
                </WishlistProvider>
              </LayoutProvider>
            </AccountProvider>
          </StoreProvider>
        </LoadingProvider>
      </CartProvider>
    </MedusaProvider>
  );
}
