"use client";

import { usePathname } from "next/navigation";
import React, { createContext, useContext, useEffect, useState } from "react";

import useHash from "../hooks/use-hash";

interface ModalProviderProps {
  children?: React.ReactNode;
}

interface ModalInterface {
  name: string;
  isShow: boolean;
}

interface SideModalContext {
  modal: ModalInterface;
  setModal: React.Dispatch<React.SetStateAction<ModalInterface>>;
  close: () => void;
}

const SideModalContext = createContext<SideModalContext | null>(null);

export const AUTHENTICATION_MODAL = "AUTHENTICATION";
export const PASSWORD_RESET_MODAL = "PASSWORD_RESET";
export const CART_MODAL = "CART";
export const ITEM_IN_CART = "ADD_ITEM";
export const ITEM_NOT_AVAILABLE = "ITEM_NOT_AVAILABLE";
export const SIDEBAR_MODAL = "SIDEBAR";
export const SEARCH_MODAL = "SEARCH";
export const FILTER_MODAL = "FILTER";
export const VIDEO_MODAL = "VIDEO";
export const ACCOUNT_ADDRESS_MODAL = "ACCOUNT_ADDRESS";
export const ACCOUNT_ADD_ADDRESS_MODAL = "ACCOUNT_ADD_ADDRESS";

export const CHECKOUT_ADDRESS = "CHECKOUT_SHIPPING";

export const SideModalProvider = ({ children }: ModalProviderProps) => {
  const [modal, setModal] = useState<{ name: string; isShow: boolean }>({
    name: "",
    isShow: false,
  });

  useEffect(() => {
    document.body.style.overflowY = modal.isShow ? "auto" : "";

    return () => {
      document.body.style.overflowY = "";
    };
  }, [modal.isShow]);

  const close = () => setModal({ name: "", isShow: false });

  return (
    <SideModalContext.Provider
      value={{
        modal,
        setModal,
        close,
      }}
    >
      {children}
    </SideModalContext.Provider>
  );
};

export const useSideModal = () => {
  const context = useContext(SideModalContext);

  if (context === null) {
    throw new Error("useSideModal must be used within a SideModalProvider");
  }

  return { ...context };
};
export const useHashToModal = (hashToMatch: string, modalToOpen: string) => {
  const { setModal } = useSideModal();
  const hash = useHash();
  const pathname = usePathname();
  useEffect(() => {
    if (hash === hashToMatch) {
      setModal({ name: modalToOpen, isShow: true });
      if (window.history) {
        window.history.replaceState(null, "", pathname);
      }
    }
  }, [hash, setModal, pathname, hashToMatch, modalToOpen]);
};
